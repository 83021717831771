/* Override Clerk modal positioning */
.cl-modalContent {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: auto !important;
}

.cl-card {
  margin: auto !important;
  position: relative !important;
}

/* Fix Clerk modal backdrop */
.cl-modalBackdrop {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Make sure the root box is properly centered */
.cl-rootBox {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  max-width: 100% !important;
}

/* Fix sign-in container for better mobile layout */
@media (max-width: 768px) {
  .cl-card {
    width: 90% !important;
    max-width: 400px !important;
  }
}

/* Fix clerk positioning when in modal mode */
.cl-modal .cl-card {
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}